<template>
  <div class="login">
    <div class="form">
      <span>
        <!-- <img class="logo" src="/img/rm-icon.svg" alt="" width="120" height="100" /> -->
        <img class="logo" src="/img/rm-icon.svg" alt="" width="180" height="64" />
        <span inline>
          <h1><em>about</em> RM-spot</h1>
        </span>
        <p>Built Thu Aug 08, 2024 - (08:32 PM)</p>
        <p>Engine version 7.0.0</p>
      </span>
      <div class="pres">
        <ul>
          <li>Must be a paid Spotify user. Then click the Connect button below.</li>
          <li>
            RM-spot makes
            <a
              href="https://docs.google.com/spreadsheets/d/1LuwEKzJZNWlM6GcvZjLJ_aWlg66gwEEkNDsbsa5Aiz8/edit?usp=sharing"
              target="_blank"
              ><strong>calls</strong></a
            >
            for
            <a href="https://salsarueda.dance/en/rueda-en/" target="_blank"><strong>Rueda de Casino</strong></a>
            dancing.
          </li>
          <li>
            RM-spot now makes
            <a
              href="https://docs.google.com/spreadsheets/d/1LuwEKzJZNWlM6GcvZjLJ_aWlg66gwEEkNDsbsa5Aiz8/edit?usp=sharing"
              target="_blank"
              ><strong>calls</strong></a
            >
            for
            <a href="https://rueda.casino/abaco/" target="_blank"><strong>Ábaco</strong></a> dancing.
          </li>
          <li>
            Data for RM-spot comes from RuedaMatic, a free Windows program. You can set your own calls! For info, see:
            <a href="https://come2think.com" target="_blank"><strong>RuedaMatic</strong></a>
          </li>
          <li>We will continue to add songs!</li>
          <h3>Use with Desktop Chrome or Firefox!</h3>
          <h4>Try Desktop mode on phone/tablet, but PC is best!</h4>
        </ul>
      </div>
      <div class="pres">
        <em>RM-spot</em> is based on <a href="https://github.com/BeardedBear/beardify"><strong>Beardify</strong></a
        >, an open source Spotify client: see source on github.
      </div>
      <div>
        <a
          class="button button--primary"
          :href="`https://accounts.spotify.com/authorize?response_type=code&client_id=${api.clientId}&redirect_uri=${api.redirectUri}&scope=${api.scopes}&code_challenge_method=S256&code_challenge=${challenge}`"
        >
          <i class="icon icon-spotify"></i> Connect with Spotify (Premium)
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { api } from "../api";
import { useAuth } from "./auth/AuthStore";
import { ref } from "vue";
import router from "../router";

const authStore = useAuth();
const challenge = ref<string | undefined>(undefined);

authStore.generateStorage(router.currentRoute.value.query.ref?.toString());
challenge.value = useAuth().storage?.codeChallenge;
</script>

<style lang="scss" scoped>
@import "../assets/scss/colors";

@keyframes pop-login {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

b {
  color: var(--primary-color);
}

.login {
  background-color: #16181d;
  background-image: url("/img/bc_background.svg");
  background-size: cover;
  display: grid;
  height: 100%;
  place-content: center;
  width: 100%;
}

.pres {
  margin-bottom: 3rem;
  text-align: left;
}

.form {
  animation: pop-login 1s ease both;
  background-color: rgb(var(--primary-color) 0.1);
  border-radius: 0.4rem;
  max-width: 35rem;
  padding: 2rem;
  text-align: center;
  will-change: transform;
}

.logo {
  height: 4rem;
  margin-bottom: 2rem;
}

.icon {
  font-size: 1.3rem;
  margin-right: 0.3rem;
  position: relative;
  top: 0.2rem;
}
</style>
