// https://date-fns.org/v2.27.0/docs/format

import format from "date-fns/format";
import { enUS } from "date-fns/locale";
import formatDuration from "date-fns/formatDuration";

const options = { locale: enUS };

export function timecode(milliseconds: number | null | undefined, padStart = false, withUnits = false): string {
  // https://stackoverflow.com/questions/19700283/how-to-convert-time-in-milliseconds-to-hours-min-sec-format-in-javascript
  if (!milliseconds) return "";
  function pad(num: number): string {
    return `${num}`.padStart(2, "0");
  }
  const asSeconds = milliseconds / 1000;

  let hours: number | undefined = undefined;
  let minutes = Math.floor(asSeconds / 60);
  const seconds = Math.floor(asSeconds % 60);

  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    minutes %= 60;
  }
  if (withUnits) {
    return formatDuration({ hours, minutes, seconds }, options);
  } else {
    return hours
      ? `${padStart ? pad(hours) : hours}:${pad(minutes)}:${pad(seconds)}`
      : `${padStart ? pad(minutes) : minutes}:${pad(seconds)}`;
  }
}

export function timecodeWithUnits(date: number): string {
  return timecode(date, false, true);
}

export function date(date: string | number): string {
  return format(new Date(date), "d MMM y", options);
}

export function tms(): string {
  // my choice of usable/informative timestamp for logging
  return new Date().toLocaleTimeString([], {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 2,
  });
}
