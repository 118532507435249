<template>
  <div v-if="ruedaMaticStore.show" ref="domRuedaMatic" class="ruedaMatic" :class="{ bye: ruedaMaticStore.bye }">
    <div class="section">
      <button
        class="button button--full"
        @click="
          notification({
            msg: 'Based on Beardify, SEE github.com',
            type: NotificationType.Error,
            id: new Date().valueOf().toString(),
          })
        "
      >
        RuedaMatic-Spotify
      </button>
    </div>

    <div class="section">
      <div class="section__title">RuedaMatic</div>
      <RmConfig />
    </div>
  </div>
</template>

<script lang="ts" setup>
import RmConfig from "./RuedaMaticConfig.vue";
import { notification } from "../../helpers/notifications";
import { onClickOutside, onKeyStroke, templateRef } from "@vueuse/core";
import { useRmConfig } from "./RuedaMaticStore";
import { NotificationType } from "../../@types/Notification";

const ruedaMaticStore = useRmConfig();

onClickOutside(templateRef("domRuedaMatic"), () => ruedaMaticStore.close());
onKeyStroke("Escape", (e) => {
  e.preventDefault();
  ruedaMaticStore.close();
});
</script>

<style lang="scss" scoped>
@use "sass:color";

.section {
  background: var(--bg-color);
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 1rem;
  padding: 0.8rem;

  &__title {
    font-size: 0.8rem;
    font-weight: 700;
    opacity: 0.5;
    text-transform: uppercase;
  }
}

.user {
  font-weight: bold;
  margin-bottom: 1.2rem;

  &__mail {
    font-size: 0.9rem;
    font-style: italic;
    font-weight: 400;
    margin-top: 0.1rem;
    opacity: 0.5;
  }
}

@keyframes pop-ruedaMatic {
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bye-ruedaMatic {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-2rem);
  }
}

.ruedaMatic {
  animation: pop-ruedaMatic ease 0.2s both;
  background-color: var(--bg-color-darker);
  border-radius: 1rem;
  box-shadow: 0 0.5rem 0.5rem rgb(0 0 0 / 15%);
  padding: 1.2rem;
  position: absolute;
  right: 1.2rem;
  top: calc(100% - 0.3rem);
  width: 15rem;
  z-index: 999;

  &.bye {
    animation: bye-ruedaMatic ease 0.2s both;
  }
}
</style>
