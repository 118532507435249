import { Track, TrackSimplified } from "../@types/Track";
import { instance } from "../api";
import { tms } from "./date";
import { usePlayer } from "../components/player/PlayerStore";
import { clearNotifications } from "./notifications"; //"./helpers/notifications";

// import spotify from "../spotify";
let playerStore;

// Mar 16 2024. UNWANTED SHUFFLE
// using playSongs can sometimes encounter an unwanted shuffle.
// symptmms:
//  - click on a track, and a different song plays
//  - clicki on any other track, and you also get a random different song
//  No sequence of in-app shuffle button clicks, playlist change, etc
//    would straighten it out:
//  WHAT WORKED:
//  open the PC App.  Due to Spotify Connect, the same song shows as playing.
//    - Stop the RM-spot play.
//    - On the PC, selecta different playlist
//    - Turn shuffle on/off there, and play that list
//    - go back to RM-Spot: the RM playlist now plays a song when clicked, in correct order
//  Best remedy: stop calling playSongs, call playSong (sincle) instead.
export function playSongs(sliceIndex: number, tracks: TrackSimplified[] | Track[]): void {
  if (!playerStore) playerStore = usePlayer(); // initialize on startup causes error
  clearNotifications();
  // spotify().activateElement(); // no effect on issue of music not playing 1st time due to no-autoplay policy
  console.log(tms(), `playSongs Autoplay gate: ${playerStore.OkAutoplay} (false quits routine)`);
  const flatTracks = tracks.map((track: TrackSimplified | Track) => track.uri);

  // 20240622:reinstated code, only feed a single URI to the play API
  //too many issues with relinking of URIs!
  // July 12 2023: the base playlist is in my personal account.  If I reorder it in Spotify app, tracks here are not played in any defined order:
  //   ... they play in a different random order, each click.  So better just drop the multisong uris and play specific song requested

  // not this: const uris = flatTracks.slice(sliceIndex);
  // not this: if (usePlayer().OkAutoplay) instance().put(`me/player/play`, { uris });
  const uris = [flatTracks[sliceIndex]];
  if (usePlayer().OkAutoplay) {
    instance().put(`me/player/play`, { uris });
    console.log(tms(), "playSongs Saving CLICKED to forecastSong: " + uris[0].substring(14));
    playerStore.forecastSong = { time: Date.now(), trackId: uris[0].substring(14) };
  } else playerStore.autoPlayWarn();
  // below code attempts to handle the play API as if it relayed the promise within the SDK
  //   for purposes of no-autoplay policy: https://developer.chrome.com/blog/autoplay/#audiovideo-elements
  // however, it is not the original but an empty promise, so the code appears to be useless.
  // ALL ATTEMPTS to detect or control the autoplay from user gestures OTHER than this main button
  //  fail.  So we'll just force the user to hit the main play button for FIRST play.
  // ---------------
  // const promise = instance().put(`me/player/play`, { uris });
  // if (promise !== undefined) {
  //   promise
  //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //     .then((_) => {
  //       // Autoplay started!
  //       console.log(tms(), "ALLOW playback (playSongs)");
  //       OkAutoplay = true;
  //     })
  //     .catch((error) => {
  //       // Autoplay was prevented.
  //       console.log(tms(), "STOP playback: " + error.toString() + " (playSongs)");
  //       OkAutoplay = false;
  //       // Show a "Play" button so that user can start playback.
  //     });
  // } else {
  //   console.log(tms(), "UNDEFINED promise for playback (playSongs)");
  // }
}

export function playSong(trackUri: string, position?: number): void {
  console.log(tms(), `playSong Autoplay gate: ${playerStore.OkAutoplay} (false quits routine)`);
  clearNotifications();
  if (playerStore.OkAutoplay)
    instance().put(`me/player/play`, position ? { uris: [trackUri], position_ms: position } : { uris: [trackUri] });
  else playerStore.autoPlayWarn();
}
