// The RuedaMatic logic - manage the rueda calls
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { tms } from "./date";
import _cloneDeep from "lodash/cloneDeep";
import _has from "lodash/has";
import _trim from "lodash/trim";
import { notification } from "../helpers/notifications"; //"./helpers/notifications";
import { NotificationType } from "../../src/@types/Notification";
import { usePlayer } from "../components/player/PlayerStore.ts";

let playerStoreRef;
export let callsLooping = false;
// false for start; true when soundRrmCall is called; false when soundRmCall finished or interrupted
// The "payoff" state is !paused but callLooping.  Then, this variable should keep initRmCall from happening.
export function resetCallsLooping() {
  callsLooping = false;
}
export function setCallsLooping() {
  callsLooping = true;
}

function playerStore() {
  if (playerStoreRef) return playerStoreRef;
  else {
    playerStoreRef = usePlayer();
    return playerStoreRef;
  }
}
class Gap {
  constructor(lenGap, endBeatIndex) {
    this.len = lenGap;
    this.endBeatIndex = endBeatIndex; // After a diagnostic pass, is the gap-ending BeatTime.
    // ...which is later used to show bad gaps on the original BeatTime, via bsVariant field.
    // After a fix pass, it is just -1: just a marker, no further use
  }
}

// helper for playSong
export function binarySearch(ar, el) {
  // http://jsfiddle.net/aryzhov/pkfst550/
  /*
   * Binary search in JavaScript.
   * Returns the index of of the element in a sorted array or (-n-1) where n is the insertion point for the new element.
   * Parameters:
   *     ar - A sorted array
   *     el - An element to search for
   *     RC: the compareFunc is the only place I customize the code for my data specifics, plus I embedded it
   *     compareFunc - A comparator function. The function takes two arguments: (a, b) and returns:
   *        a negative number  if a is less than b
   *        0 if a is equal to b
   *        a positive number of a is greater than b.
   * The array may contain duplicate elements. If there are more than one equal elements in the array,
   * the returned value can be the index of any one of the equal elements.
   */
  function compareFunc(a, b) {
    // return a - b
    if (a.$) {
      if (a.$.name.toLocaleLowerCase() === b.$.name.toLocaleLowerCase()) {
        return 0;
      } else if (a.$.name.toLocaleLowerCase() > b.$.name.toLocaleLowerCase()) {
        return +1;
      } else if (a.$.name.toLocaleLowerCase() < b.$.name.toLocaleLowerCase()) {
        return -1;
      }
    } else {
      if (a === b) {
        return 0;
      } else if (a > b) {
        return +1;
      } else if (a < b) {
        return -1;
      }
    }
  }
  let m = 0;
  let n = ar.length - 1;
  while (m <= n) {
    const k = (n + m) >> 1;
    const cmp = compareFunc(el, ar[k]);
    if (cmp > 0) {
      m = k + 1;
    } else if (cmp < 0) {
      n = k - 1;
    } else {
      return k;
    }
  }
  return -m - 1;
}

// helper for cleanMovesDict
function cleanAttribs(dictObjects, aryBoolFields, aryNumFields, isCombo) {
  // generic cleaner -- checks list with attributes that MAY be a string
  //    and need to be converted to bool, or number
  const dict = {};
  for (const [key, value] of Object.entries(dictObjects)) {
    const attr = value.$;
    // DOM objects are complicated... and tricky
    // here nodeName is actually the object field key, e.g. 'length'
    // Object.keys only returns the actual attributes, with numerical keys in the DOM object.
    //  And not the lighter (in DevTools) keys that are DOM internals.
    const base = {};
    Object.keys(attr).forEach((nkey) => {
      const field = nkey;
      const val = attr[nkey];
      let result = val; // until we test
      if (aryBoolFields.includes(field)) {
        if (typeof val !== "boolean") {
          result = val === "true";
        }
      } else if (aryNumFields.includes(field)) {
        result = isNaN(Number(val)) ? 0 : Number(val);
      }
      base[field] = result; // building a dictionary (object) for main usage of the program
    });
    if (isCombo) base.nodes = value.nodes;
    else base.comment = value.comment;
    dict[base.name] = base;
  }
  return dict;
}

// function rawToMoves(rawData) {
//    do we need to get info from the move given the move name??
//   let testedMoves;
//   try {
//     // const combos = rawData.root.combos[0].combo;
//     const moves = rawData;
//     testedMoves = moves.reduce(function (acc, cur, i) {
//       if (_has(acc, cur.$.name)) {
//         console.error( tms(), "ERR: Move name not unique! " + cur.$.name);
//       }
//       if (_trim(cur.$.name).length < 3) {
//         console.error( tms(), "ERR: Move name illegal, too short! " + cur.$.name);
//       }
//       acc[cur.$.name] = cur;
//       return acc;
//     }, {}); // initial value is an empty object
//   } catch (e) {
//     console.error( tms(), "ERR, found no moves!" + e);
//   }
//   return testedMoves;
// }

function rawToCombos(rawData) {
  // based on mermaidToXml in RME
  // result: object in vue-mermaid form
  //  also form used for creating and choosing a permutation
  let testedCombos;
  try {
    // const combos = rawData.root.combos[0].combo;
    const combos = rawData;
    testedCombos = combos.reduce(function (acc, cur, i) {
      if (_has(acc, cur.$.name)) {
        console.error(tms(), "ERR: Combo name not unique! " + cur.$.name);
      }
      if (_trim(cur.$.name).length < 3) {
        console.error(tms(), "ERR: Combo name illegal, too short! " + cur.$.name);
      }
      acc[cur.$.name] = cur;
      return acc;
    }, {}); // initial value is an empty object
  } catch (e) {
    console.error(tms(), "ERR, found no combos!" + e);
  }
  const res = {}; // result: object in vue-mermaid form
  // construct object by levels
  // first key: Combo name
  const cbos = Object.keys(testedCombos);
  cbos.forEach((c) => {
    res[c] = { $: testedCombos[c].$, nodes: {} };
    const nodes = testedCombos[c].node;
    nodes.forEach((n) => {
      res[c].nodes[n.$.id] = {}; // establish the XMLBuilder attribute property
      const o = res[c].nodes[n.$.id];
      o.text = '"' + n.$.text + '"';
      // could be undefined, ensure that returns false
      // o.allowUpshift = !n.$.allowUpshift ? false : n.$.allowUpshift.toLowerCase() === "true";
      o.allowUpshift = !n.$.allowUpshift ? false : true;
      o.allowEquivalent = !n.$.allowEquivalent ? false : true;
      if (n.$.edgeType) o.edgeType = n.$.edgeType;
      if (n.links) {
        o.next = [];
        n.links[0].link.forEach((nl) => {
          o.next.push(nl.$.target);
        });
      }
      if (n.weights) {
        o.link = [];
        n.weights[0].weight.forEach((nw) => {
          o.link.push("-- " + nw.$.value + " -->");
        });
      }
      // continue here
    });
  });
  // result: object in vue-mermaid form
  return res;
}

export function cleanMovesDict(aryObjects) {
  // We keep the Continue "move" out of the user interface, it's for timing only
  // We add it back here, to simplify the calling code to step through the sequence
  //   which includes Continue moves for timing
  const sysMove = {
    $: {
      name: "Continue",
      file: "no-such-file.mp3",
      length: "1",
      lengthextendable: "true",
      setupbars: "0",
      delaycount: "0",
    },
    comment: [
      "'Continue' is a system move name, required and uneditable.  Represents a silent interval, no mp3 file is needed",
    ],
  };

  const aryBoolFields = ["lengthextendable"];
  const aryNumFields = ["delaycount", "equivalency", "length", "level", "setupbars"];
  const dict = {};
  dict["Continue"] = sysMove;
  aryObjects.forEach((move) => {
    dict[move.$.name] = move;
  });
  return cleanAttribs(dict, aryBoolFields, aryNumFields, false); // isCombo = false;
}

export function cleanCombosDict(aryObjects) {
  // produces the playerStore currentRmSchemeCombos
  const aryBoolFields = ["startup", "hasUpshift"];
  const aryNumFields = ["maxLength", "minLength", "value", "weight"]; // value is found under node.weight, parallel to node.link
  const fmtedCombos = rawToCombos(aryObjects);
  const dictCombos = cleanAttribs(fmtedCombos, aryBoolFields, aryNumFields, true); // isCombo = true
  // NEEDED: permutations precalculated
  // for (const [key, value] of Object.entries(dictCombos)) {
  //   dictCombos[key].permutations = producePermutations(value);
  // }
  return dictCombos;
}

export function getMeanBeat(lstBeats) {
  // only called from PlayerStore, but leave it here.  Its dependencies are here
  //  and moving to PlayerStore raising TypeScript typing issues.
  // analyzeBeats(bCalcOnly = true, bFileAnalyzedWasPreexisting) {
  // ===================
  //  from desktop app RuedaMatic Editor:
  const mean = (arr) => {
    // https://vhudyma-blog.eu/mean-median-mode-and-range-in-javascript/
    let total = 0;
    for (let i = 0; i < arr.length; i++) {
      total += arr[i];
    }
    return total / arr.length;
  };
  function improvedMean(aryGaps) {
    //  called only by analyzeBeats
    // get a realistic mean, by making a token attempt to
    // throw out outlier data first.

    // 1- sort the array
    const srtAryGaps = aryGaps.map((x) => x.len);
    srtAryGaps.sort(); // arg in ActionScript was Array.NUMERIC

    // 2- find the number we can discard.  Min to leave is 50% or 5, whichever is least.
    const iDiscardMax = srtAryGaps.length - Math.max(5, srtAryGaps.length * 0.5);

    // 3 - discard the biggest gaps from either top or bottom end
    let iDiscarded = 0;
    let bDone = false;
    const orgMean = mean(srtAryGaps);
    while (bDone === false && iDiscarded < iDiscardMax) {
      const iGapDeltaStart = orgMean - srtAryGaps[0]; // start is the smallest gaps
      const iGapDeltaEnd = Math.abs(orgMean - srtAryGaps[srtAryGaps.length - 1]); // end is the largest gaps
      if (iGapDeltaStart / orgMean > iGapDeltaEnd / orgMean && iGapDeltaStart / orgMean > 0.02) {
        // Fatty is at the START, drop it
        srtAryGaps.shift();
        iDiscarded += 1;
      } else if (iGapDeltaEnd / orgMean >= iGapDeltaStart / orgMean && iGapDeltaEnd / orgMean > 0.02) {
        // Fatty is at the END, drop it
        srtAryGaps.pop();
        iDiscarded += 1;
      } else {
        // No more outlier data to trim!
        bDone = true;
      }
    }
    const iMean = mean(srtAryGaps);
    console.log(tms(), "Improved Mean iDiscarded count:" + iDiscarded);
    console.log(tms(), "Improved Mean ORIGINAL mean:" + orgMean);
    console.log(tms(), "Improved Mean IMPROVED mean:" + iMean);

    // 4- take the NEW mean
    return iMean;
  }

  // OUTER MAIN starts
  // main arrays for this section are initialized here
  const aryGaps = []; // new Array() of BeatTime objects
  //  === HERE IS THE CORRECTED ARRAY OF GAPS ===
  //  while we look for problems, we also created a fixed version
  //  however it is no longer used in the interface, there are point-fixes now
  //  that are better than magic ;-)
  const aryGapsFixed = []; // create a corrected version, Array() of BeatTime objects
  for (let iLoop = 1; iLoop < lstBeats.length; iLoop++) {
    // aryGaps, aryGapsFixed
    aryGaps.push(new Gap(lstBeats[iLoop].time - lstBeats[iLoop - 1].time, iLoop));
    aryGapsFixed.push(new Gap(lstBeats[iLoop].time - lstBeats[iLoop - 1].time, iLoop));
  }

  return improvedMean(aryGaps.concat()); // concat ensures original array is not changed in the sub
}

// CONCEPT: our button can be pushed while song is playing
//  - else, it starts play at zero
//  - it's only ENABLED if the song has beats file
export function playRmCalls() {
  // all data should be in the store already other
  //  than what needs to be constructed on the fly
  const offsSpotify = 400;
  const lstSeq = playerStore().currentRmSongSeq;
  const lstBeats = playerStore().currentRmSongBeats;
  // future variables:
  const callTimingOffs = playerStore().rmCallTimingOffs; // no offset means call on beat 1 from callers viewpoint
  // clear any looping calls
  playerStore().rmCallTimeouts.forEach((to) => clearTimeout(to));
  playerStore().rmCallTimeouts = [];
  let nIndexAt; // at the current position, what is the next beat in the song (index)?
  if (playerStore().getPosition() === 0) {
    nIndexAt = binarySearch(
      lstBeats.map((tm) => tm.time),
      0,
    );
  } else {
    const goingTime = playerStore().getPosition();
    nIndexAt = binarySearch(
      lstBeats.map((tm) => tm.time),
      goingTime,
    );
    console.log(tms(), "In progress beat search, at beat: " + (-nIndexAt - 1) + " , at: " + goingTime);
  }
  if (lstBeats.length > 0) {
    let bFirstIntervalFired = false;
    let currBeat; // interval currently playing
    let nextBeat; // next interval to be played
    const changeColors = () => {
      playerStore().rmFlashReady = !playerStore().rmFlashReady;
      // console.log(tms(), "RM beats: toggling icon fg: " + playerStore().rmFlashReady);
    };
    let beatIdx = Math.abs(-nIndexAt - 1);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const soundRmCall = () => {
      if (!callsLooping) return; // *** BAIL
      console.log(tms(), "soundRmCall invoked");
      const songWhenStarted = playerStore().currentRmSong;
      currBeat = nextBeat;
      if (beatIdx < lstBeats.length) {
        nextBeat = lstBeats[beatIdx];
        const nextTimeMs = nextBeat.time;
        console.log(tms(), "in soundRmCall: playerState?.paused: " + playerStore().playerState?.paused);
        if (nextTimeMs && (!bFirstIntervalFired || !playerStore().playerState.paused)) {
          // paused flag lags a bit, avoid false exit at start of looping
          // SCHEDULE NEXT CHANGE
          const wsElapsedMs = playerStore().getPosition(); //WAVESURFER.getCurrentTime();
          const nextTimeoutMs = nextTimeMs - wsElapsedMs;
          console.log(
            tms(),
            `nextTimeMs: ${nextTimeMs}, playerTimerMs: ${wsElapsedMs}, nextTimeoutMs: ${nextTimeoutMs}`,
          );
          let timeOffsetMs;
          let comingGapMs;
          // keep the calls coming
          let firstCallAlreadyQueued = false; // sentinel prevents making special first call more than once: see var firstIntervalFired
          if (lstSeq && lstSeq.length) {
            try {
              const curCall = lstSeq[beatIdx];
              // call should come between after 1 is being danced, before 5.  Default is 1 beat leadin in the audio clip
              // so start it at 1.33 beats into current measure (nextTimeoutMs / 6)
              // PREP to schedule the next call
              if (curCall.length > -1) {
                // -1 is just signal  time for a continuation of previous call
                // callDelay, if any, delays the call by a fraction of a measure.  This is the numerator
                // and the denominator of the fraction is always 8 (counts per measure)
                const callDelay = curCall.delaycount ? Number.parseInt(curCall.delaycount) : 0;
                // Aside from callDelay... The call should be hear a measure ahead of actually executing it
                // Should it be heard on the 1, 2, 3?  User setting 'Beat to call on' (callTimingOffs)
                if (!bFirstIntervalFired && !firstCallAlreadyQueued) {
                  firstCallAlreadyQueued = true;
                  // first time through, player is at 0 time, and the nextTimeoutMs includes the delay before first call
                  //  so for calc leadTime of call, we can't use nextTimeoutMs at all: use meanGapImproved
                  console.log(tms(), "setting special TO gap, OPENING (FIRST) INTERVAL");
                  comingGapMs = playerStore().currentRmSongAveGap;
                  timeOffsetMs =
                    offsSpotify +
                    nextTimeoutMs -
                    comingGapMs +
                    Math.round((callTimingOffs * comingGapMs) / 8 + (comingGapMs * callDelay) / 8);
                } else {
                  comingGapMs = nextTimeoutMs;
                  timeOffsetMs = Math.round((callTimingOffs * comingGapMs) / 8 + (comingGapMs * callDelay) / 8);
                }
                console.log(
                  tms(),
                  `timeOffsetMs: ${timeOffsetMs}, comingGapMs: ${comingGapMs}, nextTimeoutMs (rpt): ${nextTimeoutMs}`,
                );
                if (timeOffsetMs < 0) timeOffsetMs = 0;
                // schedule the next call: play is the method to play the call immediately (see howler docs)
                if (playerStore().playerState.paused) {
                  playerStore().rmCallTimeouts.forEach((to) => clearTimeout(to));
                  console.log(tms(), "Playerstate paused, clear calls, quit soundRmCall");
                  return; // BAIL!
                } else if (!(curCall && playerStore().currentRmHowl)) {
                  playerStore().rmCallTimeouts.forEach((to) => clearTimeout(to));
                  console.error(tms(), "no current call error!  Clear calls, quit soundRmCall");
                  return; // BAIL!
                } else if (playerStore().currentRmSong !== songWhenStarted) {
                  playerStore().rmCallTimeouts.forEach((to) => clearTimeout(to));
                  console.log(tms(), "song switched, clear calls, quit soundRmCall");
                  return; // BAIL! seems can happen due to iffy info in syncPlayerState
                } else {
                  console.log(tms(), "In TO, scheduling:" + curCall.name);
                  playerStore().rmCallTimeouts.push(
                    setTimeout(
                      () =>
                        // eslint-disable-next-line prettier/prettier
                        !console.log(tms(), "In TO, calling:" + curCall.name) &&
                        // eslint-disable-next-line prettier/prettier
                        (callsLooping
                          ? playerStore().currentRmHowl.play(curCall.file.split(".")[0])
                          : 0) &&
                        curCall
                          ? notification({
                              msg: curCall.name + ": " + (curCall.comment ? curCall.comment[0] : ""),
                              type: NotificationType.Success,
                              id: new Date().valueOf().toString(),
                            })
                          : 0,
                      timeOffsetMs,
                    ),
                  );
                }
                console.log(tms(), `In call TO: Offset: ${timeOffsetMs} Delay: ${callDelay}`);
              } else {
                // -1 length is a continuation for a longer move, not a repetition!
                console.log(
                  tms(),
                  "setTimeout for call: no call, understood as continuation of previous. But keep call loop going.",
                );
              }
            } catch (e) {
              // no call here
              console.log(
                tms(),
                "no setTimeout (no call) at time:" +
                  (currBeat ? currBeat.time : "undefined") +
                  ", understood as repetition of previous.  But keep call loop going.",
              );
            }
          }
          beatIdx += 1;
          if (bFirstIntervalFired) {
            // COLOR selection, for BG
            changeColors();
          } else {
            bFirstIntervalFired = true;
          }
          // while the song is playing, this keeps the loop going
          if (playerStore().currentRmSong === songWhenStarted && !playerStore().playerState?.paused)
            playerStore().rmCallTimeouts.push(setTimeout(soundRmCall, nextTimeoutMs)); // milliseconds delay
        }
      } else {
        // song is done, last clr chg
        changeColors();
      }
    };
    setCallsLooping();
    soundRmCall(); // kick off the timing loop
  }
}
