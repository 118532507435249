import { defineStore } from "pinia";
import { Paging } from "../../@types/Paging";
import { SimplifiedPlaylist } from "../../@types/Playlist";
import { Sidebar } from "../../@types/Sidebar";
import { instance } from "../../api";
import { isPlaylistOwner } from "../../helpers/playlist";
import router from "../../router";
import { useAuth } from "../../views/auth/AuthStore";
import { usePlaylist } from "../../views/playlist/PlaylistStore";

function isACollection(playlistName: SimplifiedPlaylist): boolean {
  return playlistName.name.toLowerCase().includes(":/rm-spot/:");
}

export const useSidebar = defineStore("sidebar", {
  state: (): Sidebar => ({
    // Rueda Playlists will show up as the first playlist section on the left!
    rmPlaylistIds: ["70S6bVj7MqcTMjxMKCKd1a", "1VUGBAtxueMSFeMWdai3bm"],
    rmPlaylists: [],
    collections: [],
    playlists: [],
  }),

  actions: {
    async getPlaylists(url: string) {
      if (url && router.currentRoute.value.name !== "Login") {
        const { data } = await instance().get<Paging<SimplifiedPlaylist>>(url);

        // tricky issue with this being called twice and double stocking the lists when log out, log in as different user
        this.playlists = [];
        this.collections = [];
        this.playlists = this.playlists.concat(data.items).filter((p) => !isACollection(p));
        this.collections = this.collections.concat(data.items).filter((p) => isACollection(p));

        if (data.next) this.getPlaylists(data.next);
      }
    },

    async addPlaylist(name: string) {
      const authStore = useAuth();
      instance()
        .post(`users/${authStore.me?.id}/playlists`, { name })
        .then(({ data }) => {
          this.playlists = [data, ...this.playlists];
        });
    },

    async addCollection(name: string) {
      const authStore = useAuth();
      instance()
        .post(`users/${authStore.me?.id}/playlists`, { name: `:/rm-spot/: ${name}` })
        .then(({ data }) => {
          this.collections = [data, ...this.collections];
        });
    },

    async removePlaylist(playlistId: string) {
      const playlistStore = usePlaylist();
      instance()
        .delete(`https://api.spotify.com/v1/playlists/${playlistId}/followers`)
        .then(() => {
          this.playlists = this.playlists.filter((playlist) => playlist.id !== playlistId);
          this.collections = this.collections.filter((collection) => collection.id !== playlistId);
          playlistStore.followed = false;
          if (isPlaylistOwner(playlistStore.playlist.owner)) {
            router.push("/");
          }
        });
    },

    // added by rc feb 2023
    async addPublicCollection(playlistId: string) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      instance()
        .put(`https://api.spotify.com/v1/playlists/${playlistId}/followers`)
        .then(({ data }) => {
          this.collections = [data, ...this.collections];
        });
    },

    refreshPlaylists() {
      this.playlists = [];
      this.collections = [];
      this.getPlaylists("me/playlists?limit=50");
    },
  },
});
