import { Notification } from "../@types/Notification";
import { useNotification } from "../components/notification/NotificationStore";

export function notification(notif: Notification): void {
  const notificationStore = useNotification();
  notificationStore.addNotification({ type: notif.type, msg: notif.msg, id: notif.id }).then(() => {
    // pass a new Date() value as notif.id
    const timeoutId = setTimeout(() => notificationStore.removeNotification(), 14000);
    notificationStore.setTimeoutId(timeoutId);
  });
}

export function clearNotifications(): void {
  const notificationStore = useNotification();
  notificationStore.clearNotifications();
}
