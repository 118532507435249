<template>
  <div class="colors">
    <div>Call Volume (off, 1, 2, 3):</div>
    <div class="schemes">
      <button
        v-for="(c, index) in aryCallVolume"
        :key="index"
        class="schemes__item"
        :class="{
          current: index === usePlayer().rmCallVolume,
          default: index === 0,
          blue: index === 1,
          apple: index === 2,
          crimson: index === 3,
        }"
        @click="usePlayer().switchCallVolume(c)"
      />
    </div>

    <div>Call on Beat (beat 1-3):</div>
    <div class="schemes">
      <button
        v-for="(c, index) in aryCallBeats"
        :key="index"
        class="schemes__item"
        :class="{
          current: index === usePlayer().rmCallTimingOffs,
          crimson: index === 0,
          apple: index === 1,
          blue: index === 2,
        }"
        @click="usePlayer().switchCallBeat(c)"
      >
        {{ index + 1 }}
      </button>
    </div>

    <div>
      <hr />
      <div>Scheme:</div>
      <div class="radio">
        <button
          class="radio__item"
          title="rueda"
          :class="{ current: usePlayer().currentRmScheme === 'rueda_normal_basica' }"
          @click="usePlayer().switchRmScheme('rueda_normal_basica')"
        >
          <!-- <i class="icon-collaborative" /> -->
          <i class="icon-repeat" />Rueda
        </button>
        <button
          class="radio__item"
          title="abaco"
          :class="{ current: usePlayer().currentRmScheme === 'abaco' }"
          @click="usePlayer().switchRmScheme('abaco')"
        >
          <!-- <i class="icon-single" /> -->
          <i class="icon-arrow-up" />Ábaco
        </button>
      </div>
    </div>
    <hr />
    <div>
      <div>Calls mode:</div>
      <div class="radio">
        <button
          class="radio__item"
          title="Auto"
          :class="{ current: usePlayer().autoCallOn === true }"
          @click="usePlayer().switchAutoCall(true)"
        >
          <!-- <i class="icon-check" /> -->
          Auto
        </button>
        <button
          class="radio__item"
          title="Preset"
          :class="{ current: usePlayer().autoCallOn === false }"
          @click="usePlayer().switchAutoCall(false)"
        >
          Preset
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
// import { useRmConfig } from "./RuedaMaticStore";
import { usePlayer } from "./../player/PlayerStore"; // don't instantiate til use, initialization race could pe a prob

const aryCallBeats = [0, 1, 2];
const aryCallVolume = [0, 1, 2, 3];
</script>

<style lang="scss" scoped>
.schemes {
  display: flex;
  justify-content: space-evenly;

  &__item {
    $s: 1rem;

    border: 0;
    cursor: pointer;
    flex: 1;
    height: $s;
    padding: 0;
    position: relative;
    transition: all ease 0.2s;

    &:first-of-type {
      border-radius: $s 0 0 $s;
    }

    &:last-of-type {
      border-radius: 0 $s $s 0;
    }

    &::after {
      $o: 0.3rem;

      background-color: white;
      border-radius: $s;
      bottom: $o;
      content: "";
      left: $o * 2;
      position: absolute;
      right: $o * 2;
      top: $o;
      transform: scaleX(0);
      transition: all ease 0.2s;
      will-change: transform;
    }

    &.current {
      &::after {
        transform: scaleX(1);
        z-index: 0;
      }
    }

    &.blue {
      background-color: #15acde;
    }

    &.default {
      background-color: #9064ff;
    }

    &.crimson {
      background-color: #de1c3e;
    }

    &.apple {
      background-color: #28aa1b;
    }
  }
}

.radio {
  display: flex;
  justify-content: space-between;

  &__item {
    $radius: 0.4rem;

    background-color: var(--bg-color);
    border: 0;
    color: currentcolor;
    cursor: pointer;
    margin-top: 0.8rem;
    padding: 0.5rem 0;
    width: 100%;

    &:first-of-type {
      border-radius: $radius 0 0 $radius;
    }

    &:last-of-type {
      border-radius: 0 $radius $radius 0;
    }

    &.current {
      background-color: #9064ff;
      color: white;
    }
  }
}
</style>
