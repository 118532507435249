export enum NotificationType {
  Error,
  Success,
  Warning,
}
export interface NotificationStore {
  notifications: Notification[];
  timeoutIds: NodeJS.Timeout[];
}

export interface Notification {
  type: NotificationType;
  msg: string;
  id: string;
}
