import { usePlayer } from "./components/player/PlayerStore";
import { useAuth } from "./views/auth/AuthStore";
// import { notification } from "./helpers/notifications";
// import { NotificationType } from "./@types/Notification";

window.onbeforeunload = function (): void {
  // Browser refresh while using RM doesn't work otherwise
  window.location.href = window.location.origin;
};

export default window.onSpotifyWebPlaybackSDKReady = (): Spotify.Player => {
  const player = new Spotify.Player({
    name: "RMSpot",
    getOAuthToken: (cb): void => cb(useAuth().accessToken),
    volume: 0.5, // since May 2023 user cannot change volume, so make it low enough to always hear the calls
    // volume: 0.6, // since May 2023 user cannot change volume, so make it low enough to always hear the calls
  });

  player.connect();

  player.addListener("ready", ({ device_id }) => {
    usePlayer().thisDevice(device_id);
  });

  player.addListener("player_state_changed", (state) => {
    usePlayer().syncPlayerState(state);
    // rc q: how can 2nd call to sync be useful? 1st seems to be ok - comment out for now -rc
    // if (document.hasFocus()) usePlayer().syncPlayerState(state);
  });

  return player;
};
