import { defineStore } from "pinia";
import { RMConfig } from "../../@types/RuedaMatic";

export const useRmConfig = defineStore("ruedamatic", {
  state: (): RMConfig => ({
    bye: false,
    show: false,
  }),

  actions: {
    open() {
      this.show = true;
    },

    close() {
      this.bye = true;
      setTimeout(() => {
        this.show = false;
        this.bye = false;
      }, 200);
    },
  },
  persist: {
    key: "rm-spot-config",
  },
});
