<template>
  <div v-if="playlistStore.playlist.name === ''" class="loader"><Loader /></div>
  <PageScroller v-else>
    <div class="playlist">
      <Header not-fit />
      <template v-if="playlistStore.playlist.owner.display_name === 'Spotify'">
        <iframe src="https://come2think.com/RuedaMatic/" title="RuedaMatic docs"></iframe>
      </template>
      <Tracks v-else :track-list="playlistStore.tracks" />
    </div>
  </PageScroller>
</template>

<script lang="ts" setup>
// import { computed } from "vue";
import { usePlaylist } from "./PlaylistStore";
import Loader from "../../components/LoadingDots.vue";
import PageScroller from "../../components/PageScroller.vue";
import Tracks from "../../components/playlist/PlaylistTracks.vue";
// import { isAlbum, isEP, isSingle, useCheckLiveAlbum } from "../../helpers/useCleanAlbums";
import Header from "../../components/playlist/PlaylistHeader.vue";
import { usePlayer } from "../../components/player/PlayerStore";

const playerStore = usePlayer();
const props = defineProps<{ id: string }>();
const playlistStore = usePlaylist();
// const albums = computed(() =>
//   playlistStore.tracks
//     .filter((track) => isAlbum(track.track.album) && !useCheckLiveAlbum(track.track.album.name))
//     .map((e) => e.track.album),
// );
// const eps = computed(() => playlistStore.tracks.filter((track) => isEP(track.track.album)).map((e) => e.track.album));
// const singles = computed(() => playlistStore.tracks.filter((track) => isSingle(track.track.album)));

// RuedaMatic playlist handling here!
const lstPresetsAbaco = "1VUGBAtxueMSFeMWdai3bm";
const lstPresetsRueda = "70S6bVj7MqcTMjxMKCKd1a";
const lstAll = "5nPPeIjsIjYecSQwt2QajM";

playlistStore.clean().finally(() => {
  if (props.id === lstPresetsAbaco) {
    playerStore.pause();
    playerStore.switchRmScheme("abaco");
    playerStore.autoCallOn = false;
  } else if (props.id === lstPresetsRueda) {
    playerStore.pause();
    playerStore.switchRmScheme("rueda_normal_basica");
    playerStore.autoCallOn = false;
  } else if (props.id === lstAll) {
    playerStore.pause();
    // playerStore.autoCallOn = true; // let them set, prob will default to preset if available, auto if no such
  }

  playlistStore.getPlaylist(`playlists/${props.id}`);
  playlistStore.getTracks(`playlists/${props.id}/tracks`);
});
</script>

<style lang="scss" scoped>
@use "sass:color";
@import "../../assets/scss/colors";
@import "../../assets/scss/responsive";

.block {
  margin-bottom: 2rem;
}

.playlist {
  margin: 0 auto;
  padding: 2rem;
  width: 100%;
}

.loader {
  display: grid;
  place-content: center;
}
</style>
