import axios, { AxiosInstance } from "axios";
import { useAuth } from "./views/auth/AuthStore";
// import { tms } from "./helpers/date";

export const api = {
  url: "https://api.spotify.com/v1/",
  // clientId: "15d050a71bad47ffa2627d4d6a1db06c", // new Spotify app "Development mode" called "rm-spot"
  clientId: "284a1e34fa404212937cdc04dea9759b", // Old Spotify app with "QUOTA EXTENSION" called "RuedaMaticSpotify"
  // clientId: "29a0936f4c6c46399f33f6f60a0855e8", // original clientId in codebase of github beardify
  redirectUri:
    process.env.NODE_ENV !== "production"
      ? "http://localhost:3000/auth"
      : window.location.origin === "https://rm-spot.com"
      ? "https://rm-spot.com/auth"
      : "https://rm-spot-test.com/auth",
  scopes:
    "user-read-private,user-modify-playback-state,user-read-playback-state,user-read-currently-playing,playlist-read-private,playlist-read-collaborative,playlist-modify-private,playlist-modify-public,user-follow-modify,user-follow-read,streaming,user-read-email,user-top-read,user-library-read,user-read-playback-position",
};

export function instance(): AxiosInstance {
  const authStore = useAuth();
  // console.log(tms(), "API called instance()");
  return axios.create({
    baseURL: api.url,
    timeout: 5000,
    headers: {
      Authorization: `Bearer ${authStore.accessToken}`,
      "Content-Type": "application/json",
    },
  });
}
