/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { defineStore } from "pinia";
import { tms } from "../../helpers/date"; // timestamp for console

import { defaultCurrentlyPlaying, defaultDevice, defaultRMScheme } from "../../@types/Defaults";
import { DevicesResponse } from "../../@types/Device";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Player, BeatTime } from "../../@types/Player"; // RMScheme not used yet
import { instance } from "../../api";
import spotify from "../../spotify";
import AutoFiller from "../../helpers/AutoFiller.js";

import {
  callsLooping,
  resetCallsLooping,
  // setCallsLooping, // noted for completeness
  getMeanBeat,
  playRmCalls,
  cleanMovesDict,
  cleanCombosDict,
} from "../../helpers/playerControlsRuedamatic.js";
import { Howl } from "howler";
import { notification, clearNotifications } from "../../helpers/notifications"; //"./helpers/notifications";
import { NotificationType } from "../../../src/@types/Notification";
import _cloneDeep from "lodash/cloneDeep";

export const usePlayer = defineStore("player", {
  state: (): Player => ({
    // Ruedamatic data:
    //  General (on startup)
    rmSongIdMapping: {},
    // Scheme (on scheme select)
    currentRmScheme: defaultRMScheme,
    currentRmHowl: null,
    currentRmSchemeMoves: {},
    currentRmSchemeCombos: {},
    rmSchemeReady: false,
    rmFlashReady: false, // variable to trigger flashing as beats are playing
    rmHowlerReady: false,
    // Song specific (on song select)
    currentRmSong: "", // Spotify song ID
    forecastSong: {}, // see "track relinking"; request songid A, spotify plays B instead: same song, diff market.  So we "forecast" based on next song
    currentRmSongBeats: [],
    currentRmSongAveGap: 0,
    currentRmSongSeq: [],
    currentRmSongTags: "", // intended to show tags: due to track relinking, ID can't be used to show them!
    rmPlayingPreset: true, // ✉ sealed envelope if playing preset: dynamically set to ✈ airplane if "winging it" due to preset missing.  Shows by current track in GUI
    rmSongReady: false,
    rmCallTimeouts: [],
    rmHandleSeekFlag: false,
    // metric
    rmSchemeReadyTime: 0,
    rmSongReadyTime: 0,
    rmHowlerReadyTime: 0,
    // legacy data:
    devices: {
      activeDevice: defaultDevice,
      list: [],
    },
    thisDeviceId: "",
    currentlyPlaying: defaultCurrentlyPlaying,
    currentItemFromSDK: null,
    currentPositionFromSDK: 0,
    playerState: null,
    // added for RM logic
    playerStatePrevious: null,
    autoFiller: {},
    // v - the only user gesture accepted at first seems to be the play/pause button.  Other clicks to play won't work until that one is used.
    OkAutoplay: false, // due to AudioContext autoplay policy https://developer.chrome.com/blog/autoplay/
    // v - controls for automated setting of move sequence: no loading of preset sequence is required: called "autoset"
    autoCallOn: false, // true: we make up a sequence || false: we load the sequence imported from RuedaMatic Editor desktop app
    autosetLimit: 0,
    autosetDone: false,
    autosetComboWeights: [],
    autosetCurrentCombo: {},
    autosetCurrentPermWeights: [],
    autosetCurrentPermutation: {},
    autosetBeatIndex: 2, // 2 "empty" array items before first move
    autosetAllow1MissIn3: true,
    rmCallTimingOffs: 1,
    rmCallVolume: 3,
  }),

  actions: {
    play(): void {
      // Click on the big Play button, at the bottom center.
      // This is the key Play user gesture that is accepted by Chrome for the "no autoplay" override.
      // SO: we require user to come here first.
      instance().put("me/player/play", { device_id: this.devices.activeDevice });
      // this code attempts to handle the play API as if it relayed the promise within the SDK
      //   for purposes of no-autoplay policy: https://developer.chrome.com/blog/autoplay/#audiovideo-elements
      // however, it is not the original but an empty promise, so the code appears to be useless.
      // ALL ATTEMPTS to detect or control the autoplay from user gestures OTHER than this main button
      //  fail.  So we'll just force the user to hit the main play button for FIRST play.
      if (!this.OkAutoplay) {
        // normally, the autoplay problem FORCES the user to hit the main PLAY when they don't want to.
        //   so let's make it stop as soon as possible.  But only the first (forced) time
        setTimeout(() => {
          instance().put(`me/player/pause`);
          resetCallsLooping();
          setTimeout(() => {
            this.OkAutoplay = true; // delay so we can detect if a pause was this (self inflicted) one
            console.log(tms(), "PlayerStore play called, autoplay gate is: " + this.OkAutoplay);
          }, 500);
        }, 500);
        // less delay might not work

        // notification doesn'tseem to work here...??
        // notification({
        //   msg: "AUTOPLAY: due to autoplay rules and browser limitations, your first play action may be prevented by the browser.  Second try works!",
        //   type: NotificationType.Warning,
        //   id: (new Date().valueOf() + 1).toString(), // +1 to avoid duplicate id from Vue
        // });
      }
      clearNotifications();
      console.log(tms(), "PlayerStore play called, autoplay gate is: " + this.OkAutoplay);
    },

    pause(): void {
      clearNotifications();
      console.log(tms(), "PlayerStore pause called");
      try {
        instance().put("me/player/pause", { device_id: this.devices.activeDevice });
      } catch (e) {
        const err = `Error: parsing beats: ${e} `;
        console.error(tms(), "Possible DEVICE ERR: " + err.split(/\r?\n/)[0]);
      }
      resetCallsLooping();
    },

    next(): void {
      instance().post("me/player/next");
    },

    async getDeviceList() {
      instance()
        .get<DevicesResponse>("me/player/devices")
        .then(({ data }) => {
          const activeDevice = data.devices.find((d) => d.is_active);
          this.devices.list = data.devices;
          if (!data.devices.length) spotify().connect();
          if (!this.playerState?.paused && activeDevice) {
            this.devices.activeDevice = activeDevice;
          } else if (activeDevice?.is_active) {
            this.devices.activeDevice = activeDevice;
          } else {
            this.setDevice(this.thisDeviceId);
          }
        });
    },

    getPosition() {
      const nudge = -160;
      // if we start to play, we expect to get the player state chg event (syncPlayerState)
      // The position field is static, it has a corresponding timestamp
      // So our curr play posn is  =  saved posn + time since we got it
      let val = Date.now() - (this.playerState?.timestamp || 0) + (this.playerState?.position || 0 + nudge);
      val = val >= 0 ? val : 0;
      return val;
    },

    setDevice(deviceId: string | null) {
      instance()
        .put("me/player", { device_ids: [deviceId] })
        .then(() => {
          instance()
            .get<DevicesResponse>("me/player/devices")
            .then(({ data }) => {
              this.devices.list = data.devices;
              const activeDevice = data.devices.find((d) => d.id === deviceId);
              if (activeDevice) this.devices.activeDevice = activeDevice;
            });
        });
    },

    setVolume(volume: number) {
      instance()
        .put(`me/player/volume?volume_percent=${volume}`)
        .then(() => (this.devices.activeDevice.volume_percent = volume));
    },

    clearRmSongData() {
      console.log(tms(), "clearRmSongData: SONG CHANGE: clearing RM song data.");
      this.currentRmSong = ""; // spotify song id
      this.currentRmSongBeats = [];
      this.currentRmSongAveGap = 0;
      this.currentRmSongSeq = [];
      this.rmSongReady = false;
      this.rmCallTimeouts.forEach((to) => clearTimeout(to));
      this.rmCallTimeouts = [];
      this.rmHandleSeekFlag = false; // you can only seek in the current song
    },

    autoFill() {
      let raw: any[] = [];
      let audit: any = "";
      const seq: any[] = [];

      this.autoFiller = new AutoFiller(
        this.currentRmSongSeq, // sequence normally empty: note it will be checked for cambio measures, and Pasito moves inserted, during load
        this.currentRmSchemeMoves,
        this.currentRmSchemeCombos,
        this.currentRmSongBeats,
        this.autosetBeatIndex,
        this.currentRmSong,
        true, // fixWebData flag, tells callee to specially transform data from the Web app to be compatible with RM Editor on PC
      );
      [raw, audit] = this.autoFiller.autoFill();
      if (this.currentRmSchemeMoves) {
        let offs = this.autosetBeatIndex;
        for (let i = 0; i < raw.length; i++) {
          let fullMove;
          if (raw[i]) {
            fullMove = this.currentRmSchemeMoves[raw[i].move];
          } else {
            fullMove = this.currentRmSchemeMoves["Continue"];
          }
          seq[offs] = fullMove;
          offs += raw[i] ? raw[i].length || 1 : 1; // Continue is 1; Cambio (Cruce) is marked 0 len but still takes up a slot
        }
      } else console.error(tms(), "currentRmSchemeMoves seems to be null in fetchSongSequence()");
      this.currentRmSongSeq = seq;
      console.log(audit);
    },

    syncPlayerState(state: Spotify.PlaybackState) {
      this.playerStatePrevious = this.playerState;
      this.playerState = state;
      // if (this.currentlyPlaying.repeat_state === "track") {
      //   // 'track' is only seen AFTER repeat button clicked, until the next song starts
      //   // SO: we stop the next song that is started automatically, and we will
      //   // repeat the last song + its moves on clicking the big PLAY button
      //   instance().put(`me/player/pause`); // this could be the next track in queue
      // }
      // some state logging for trouble shooting
      console.log(
        tms(),
        `syncPlayerState, pos: ${state.position}, callsLooping: ${callsLooping}, seek: ${
          this.rmHandleSeekFlag
        }, autoCallOn: ${this.autoCallOn}
          song: ${state.track_window.current_track.id}, prev: ${this.playerStatePrevious?.track_window.current_track.id}
          repeat (loc): ${this.currentlyPlaying.repeat_state.toUpperCase()}, repeat (sdk): ${
          ["OFF", "CONTEXT", "TRACK"][state.repeat_mode]
        }, shuffle: ${state.shuffle}, context uri: ${state.context.uri}, next trks: ${
          state.track_window.next_tracks.length
        }
          paused: ${state.paused}, previous: ${this.playerStatePrevious?.paused}`,
      );

      if (!this.OkAutoplay) return false; // **** BAIL *****

      // STRAIGHTFORWARD expected case: we have the song playing, and we just load its data.
      // FAILURE MODE: "RELINKING" happens.  We ask for one Spotify ID for a song, and we
      //  get a different song.
      //  "RELINKING" is related to ownership of rights in different markets
      // We have 2 recovery strategies:
      //  if a song is found playing in this event, THEN we either CLICKED ON IT (case 1),
      //    or it plays automatically after being NEXT in the playlist (case 2)
      // CASE 1
      // You ask spotify to play one songId, and the trackId played may be different
      // SO: when a song is playing, we see if we JUST NOW clicked on a song to play.
      // If ID is different: THEN we map the playing trackID back to our original songId for purposes of retreiving the calls etc.
      // CASE 2
      // If not CASE 1: when a song plays, we cache the "next song" from the track_window field object
      //   SO if an unknown song shows up in this event, AND Case 1 does not apply:
      //   THEN we use the "previous next" and assume that is the song playing.

      // filtering flags for cases OTHER THAN start playing: pause, seek,
      if (state.paused && !this.playerStatePrevious?.paused) {
        // was playing, now it's paused
        if (!Object.keys(this.rmSongIdMapping).includes(state.track_window.current_track.id || "")) {
          // not our song, just clear data and quit
          resetCallsLooping();
          this.clearRmSongData();
          return; // **** BAIL *****
        }
      }

      if (state.track_window.current_track.id !== this.playerStatePrevious?.track_window.current_track.id) {
        // if its a new song playing
        // notification({
        //   msg: "NEW SONG! Settings are in upper left corner, MIC icon!",
        //   type: NotificationType.Warning,
        //   id: new Date().valueOf().toString(),
        // });
        resetCallsLooping(); // flag to stop the calls being made
        this.clearRmSongData(); // out with the old song
        clearNotifications();
        notification({
          msg: "SCHEME: " + this.currentRmScheme + " CALL MODE: " + (this.autoCallOn ? "Automatic" : "Preset"),
          type: NotificationType.Warning,
          id: (new Date().valueOf() + 1).toString(), // +1 to avoid duplicate id from Vue
        });

        const timeNow = Date.now();
        let myTrackId;
        myTrackId = state.track_window.current_track.id;
        if (timeNow - this.forecastSong.time < 500) {
          // once we allow a click to call playSongs API, a song can come up for play here
          //  that is relinked out of the queue.  So be ready with the
          if (this.forecastSong.trackId !== myTrackId) {
            console.log(
              tms(),
              "trackId OVERRIDE due to relink RMS: " + this.forecastSong.trackId + " Spotify: " + myTrackId,
            );
            myTrackId = this.forecastSong.trackId; // OVER RIDE
          }
        }
        // state will show paused at first, but if you click on a song: it always plays
        if (Object.keys(this.rmSongIdMapping).includes(myTrackId || "")) {
          this.currentRmSong = myTrackId || "";
          this.initRmSong(this.rmSongIdMapping[this.currentRmSong].file);
        } else {
          // not our song: this shouldn't happen now
        }
      } else {
        // it's the same song as previous calls:  if pause->then->play we can call it a seek
        if (Object.keys(this.rmSongIdMapping).includes(state.track_window.current_track.id || "")) {
          // pause then play same RM song, is the same as a seek
          if (!state.paused && this.playerStatePrevious?.paused) playRmCalls(this);
          if (this.rmHandleSeekFlag) {
            playRmCalls(this);
            this.rmHandleSeekFlag = false; // handled
          }
        }
      }
    },

    toggleShuffle() {
      if (this.currentlyPlaying.shuffle_state) {
        instance()
          .put("me/player/shuffle?state=false")
          .then(() => (this.currentlyPlaying.shuffle_state = false));
      } else {
        instance()
          .put("me/player/shuffle?state=true")
          .then(() => (this.currentlyPlaying.shuffle_state = true));
      }
    },

    ensureRepeatOff() {
      if (this.currentlyPlaying.repeat_state === "off") {
        this.currentlyPlaying.repeat_state = "track";
        // anything but "off" in the API screws up the play order
        // ... we use our repeat_state field  to repeat songs and calls without using Spotify's repeat state
      } else {
        instance()
          .put("me/player/repeat?state=off")
          .then(() => (this.currentlyPlaying.repeat_state = "off"));
      }
    },

    seek(progress: number) {
      console.log(tms(), "PlayerStore seek is happening...");
      instance()
        .put(`me/player/seek?position_ms=${Math.round(progress)}`)
        .then(() => (this.currentlyPlaying.progress_ms = progress)); // rc? not used?
      this.rmHandleSeekFlag = true; // reset in sync after we handle it
    },

    thisDevice(deviceId: string) {
      this.thisDeviceId = deviceId;
      this.getDeviceList();
    },

    // TODO rc 20230119 this routine not used, also its dependent data not used
    //  remove this but also cleanup the unused dependents in PlayerEpisode.vue
    // note: this.syncPlayerState() is where the realtime update happens.
    updateFromSDK(args: Spotify.Track, position: number) {
      this.currentItemFromSDK = args;
      this.currentPositionFromSDK = position;
    },

    autoPlayWarn(): void {
      console.log(tms(), "autoPlayWarn: Showing notification");
      notification({
        msg: "CLICK the big PLAY/PAUSE button (at the bottom) to start!",
        type: NotificationType.Warning,
        id: new Date().valueOf().toString(),
      });
      notification({
        msg: "Then click on a RuedaMatic song",
        type: NotificationType.Warning,
        id: new Date().valueOf().toString(),
      });
    },

    initRmScheme(player: any): any {
      // setup the data for rueda calling
      player.rmSchemeReady = false;
      player.rmSchemeReadyTime = Date.now();
      const rmSchemeId = "scheme_" + player.currentRmScheme;

      const fetchCalls = (rmSchemeId: string, that: any): any => {
        // fetch the appended MP3 calls for the scheme (i.e. "sound sprite")
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        return new Promise((resolve) => {
          console.log(tms(), "fetch Calls (Howler) promise is starting");
          fetch(window.location.origin + "/rm-assets/" + rmSchemeId + "/" + rmSchemeId + ".json")
            .then((response) => response.json())
            .then((data) => {
              this.rmHowlerReady = false;
              this.rmHowlerReadyTime = Date.now(); // clear
              // spotify().activateElement(); // no effect on issue of music not playing 1st time due to no-autoplay policy
              const sound = new Howl({
                src: [window.location.origin + "/rm-assets/" + rmSchemeId + "/" + rmSchemeId + ".mp3"],
                sprite: data,
                onload: function (): void {
                  that.rmHowlerReady = true;
                  that.rmHowlerReadyTime = Date.now() - that.rmHowlerReadyTime;
                  console.log(tms(), "Howler is ready!");
                  resolve("Howler is ready!");
                },
                onloaderror: function (id, err: any): void {
                  console.error(tms(), "error loading howl: " + id + ", " + err.message);
                },
                onplayerror: function (id, err: any): void {
                  console.error(tms(), "error playing howl: " + id + ", " + err.message);
                },
              });
              // if (!("sound" in window)) {
              if (typeof sound === "undefined") {
                // https://stackoverflow.com/questions/3390396/how-can-i-check-for-undefined-in-javascript
                console.log(tms(), "HOWL init failed, null");
              } else {
                console.log(tms(), "HOWL init OK");
                this.currentRmHowl?.unload(); // extra repititions otherwise
                this.currentRmHowl = sound;
                this.switchCallVolume(this.rmCallVolume);
                console.log(tms(), "volume set to " + this.rmCallVolume);
              }
            });
        });
      };

      const fetchBeatsMapping = (rmBeatsMapping: string): any => {
        // fetch the mapping of Spotify song ID to RM beats filename
        return new Promise((resolve) => {
          console.log(tms(), "fetch Beats promise is starting");
          fetch(window.location.origin + "/rm-assets/" + rmBeatsMapping)
            .then((response) => response.json())
            .then((data) => {
              this.rmSongIdMapping = data;
              console.log(tms(), "Beats file mapping loaded.");
              resolve("Beats file mapping loaded, count: " + Object.keys(this.rmSongIdMapping).length);
            });
        });
      };

      // get data required for RMscheme: moves.xml, moves.json, moves sprite
      const fetchMoves = (rmSchemeId: string): any => {
        return new Promise((resolve) => {
          console.log(tms(), "fetch Moves promise is starting");
          fetch(window.location.origin + "/rm-assets/" + rmSchemeId + "/" + "moves.json")
            .then((response) => response.text())
            .then((data) => {
              let moves;
              try {
                moves = JSON.parse(data);
              } catch (e) {
                console.error(tms(), `Error: parsing moves collection: ${e} `);
              }
              this.currentRmSchemeMoves = cleanMovesDict(moves);
              console.log(tms(), "Moves dictionary is loaded.");
              resolve("Moves dictionary is loaded, count: " + Object.keys(this.currentRmSchemeMoves || {}).length);
            });
        });
      };

      // get data required for RMscheme combos, which depends on moves
      const fetchCombos = (rmSchemeId: string): any => {
        return new Promise((resolve) => {
          console.log(tms(), "fetch Combos promise is starting");
          fetch(window.location.origin + "/rm-assets/" + rmSchemeId + "/" + "combos.json")
            .then((response) => response.text())
            .then((data) => {
              let combos;
              try {
                combos = JSON.parse(data);
              } catch (e) {
                console.error(tms(), `Error: parsing combos collection: ${e} `);
              }
              this.currentRmSchemeCombos = cleanCombosDict(combos, this);
              console.log(tms(), "Combos dictionary is loaded.");
              resolve("Combos dictionary is loaded, elements: " + Object.keys(this.currentRmSchemeCombos || {}).length);
            });
        });
      };

      const getSchemeAll = (rmSchemeId: string, that: any): any => {
        Promise.all([
          fetchCalls(rmSchemeId, that),
          fetchBeatsMapping("rm-spot-songs.json"),
          fetchMoves(rmSchemeId),
          fetchCombos(rmSchemeId),
        ])
          .then((allResults) => {
            that.rmSchemeReadyTime = Date.now() - that.rmSchemeReadyTime;
            that.rmSchemeReady = true;
            that.rmFlashReady = true; // this is the var tied to "active" css class for the RM icon.
            // by toggling as beats play, we can visually verify the accuracy of beats per spotify play engine
            console.log(
              tms(),
              `Promise.all.then: Beats mapping, Calls, Moves, Combos Promise.all results: ${JSON.stringify(
                allResults,
              )}, ms: ${that.rmSchemeReadyTime}`,
            );
            console.log(tms(), "Scheme is ready!");
            // console.log(tms(), "Promise.all results:" + allResults);
          })
          .catch((error) => {
            console.error(tms(), error);
          });
      };
      getSchemeAll(rmSchemeId, this);
    },

    initRmSong(rmSongFname) {
      this.ensureRepeatOff(); // ensure repeat state is off, else relinking is a problem
      console.log(
        tms(),
        "initRmSong ('" + rmSongFname + "'), ID per track_window: " + this.playerState?.track_window.current_track.id,
      );
      // clean up the old song, which might be looping
      this.rmCallTimeouts.forEach((to) => clearTimeout(to));
      this.rmCallTimeouts = [];
      // this.playerState ? (this.playerState.position = 0) : null; // SDK notifications seem unreliable, let's ensure set to zero

      const rmSchemeId = "scheme_" + this.currentRmScheme;

      const fetchSongBeats = (rmSongFname: string): any => {
        return new Promise((resolve, reject) => {
          fetch(window.location.origin + "/rm-assets/compases_para_canciones/" + rmSongFname)
            .then((response) => response.text())
            .then((data) => {
              let beats;
              try {
                beats = JSON.parse(data).map(
                  (beat) => new BeatTime(beat._, beat.$.gear || "mellow", beat.$.comment || ""),
                );
              } catch (e) {
                const err = `Error: parsing beats: ${e} `;
                console.error(tms(), err);
                reject(new Error(err));
              }
              this.currentRmSongBeats = beats;
              this.currentRmSongAveGap = getMeanBeat(beats);
              console.log(tms(), "Beats are loaded.");
              resolve("Beats are loaded, count: " + this.currentRmSongBeats.length);
            });
        });
      };
      // after song is known
      const fetchSongSequence = (rmSongFname: string): any => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        // fetch the beats files available, i.e. all songs available for next steps
        return new Promise((resolve, reject) => {
          let jseq;
          const seq: any[] = [];
          if (!this.autoCallOn) {
            this.rmPlayingPreset = true; // sealed envelope = PRESET (shows in GUI)
            fetch(window.location.origin + "/rm-assets/" + rmSchemeId + "/secuencias_para_canciones/" + rmSongFname) // cust name for seq file in json format
              .then((response) => response.text())
              .then((data) => {
                try {
                  jseq = JSON.parse(data);
                } catch (e) {
                  const err = `Error: parsing seq: ${e} `;
                  console.error(tms(), err);
                  // reject(new Error(err));
                  // instead of reject, let's try autofill
                  try {
                    this.autoFill();
                    // playRmCalls(this);
                    notification({
                      msg: "Preset Sequence is MISSING for this song - AUTOFILL moves instead!",
                      type: NotificationType.Warning,
                      id: new Date().valueOf().toString(),
                    });
                    this.rmPlayingPreset = false; // winging it (shows in GUI)
                    resolve("Seq is missing, need autofill!");
                  } catch (e) {
                    reject(new Error(err));
                  }
                }
                if (that.currentRmSchemeMoves) {
                  this.currentRmSongTags = jseq ? jseq.tags : "";
                  try {
                    jseq.moves.map((m) => {
                      for (let ix = 0; ix < that.currentRmSchemeMoves![m.$.name].length; ix++) {
                        seq.push(_cloneDeep(that.currentRmSchemeMoves![m.$.name]));
                        if (ix > 0) seq[seq.length - 1].length = -1;
                      }
                      if (that.currentRmSchemeMoves![m.$.name].length === 0)
                        // special case of a beat change in the music, special moves are marked with 0 length
                        seq.push(_cloneDeep(that.currentRmSchemeMoves![m.$.name]));
                    });
                  } catch (err) {
                    console.log(
                      tms(),
                      "Err trapped but likely already handled via Promise rejection:" + (err as Error).message,
                    );
                  }
                } else console.error(tms(), "currentRmSchemeMoves seems to be null in fetchSongSequence()");
                this.currentRmSongSeq = seq;
                resolve("Seq is loaded, count: " + this.currentRmSongSeq.length);
              });
          } else {
            resolve(
              "See parent PromiseAll for code to insert clave switch 'cambio' calls.. since that depends on beats being loaded",
            );
            // getMovesForSong(this); //
            // console.log(tms(), "Seq is generated.");
            // resolve("Seq is generated, count: " + this.currentRmSongSeq.length);
          }
        });
      };

      const fetchSongPartsAndPlay = (rmSongFname: string): any => {
        this.rmSongReadyTime = Date.now(); // init for later calc of elapsed time
        const promise1 = fetchSongBeats(rmSongFname + ".json"); // the big one
        const promise2 = fetchSongSequence(rmSongFname + ".jseq");
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        Promise.all([promise1, promise2]).then(function (results) {
          if (that.autoCallOn || results[1].includes("need autofill")) {
            that.autoFill();
            that.rmPlayingPreset = false; // winging it (shows in GUI)
          }

          that.rmSongReady = true;
          that.rmSongReadyTime = Date.now() - that.rmSongReadyTime;
          console.log(tms(), results + ", elapsed: " + that.rmSongReadyTime);
          console.log(tms(), "Promise.all done for song, playRmCalls is called now.");
          // instance().put(`me/player/play`, { position_ms: 1 });
          playRmCalls(that);
        });
      };

      fetchSongPartsAndPlay(rmSongFname);
    },

    switchCallBeat(beat: number) {
      // user chooses to call on beat 1, 2, or 3
      //  translates to offset 0, 1, or 2
      this.rmCallTimingOffs = beat;
    },

    switchCallVolume(volume: number) {
      this.rmCallVolume = volume;
      const volScaler = { 0: 0, 1: 0.25, 2: 0.65, 3: 1.0 }; // a small nod to logarithmic perception
      this.currentRmHowl?.volume(volScaler[volume]);
    },

    switchAutoCallsetLimit(limit: number) {
      this.autosetLimit = limit;
    },

    switchRmScheme(scheme: string) {
      this.currentRmScheme = scheme;
      this.initRmScheme(this);
      if (this.currentRmSong) this.initRmSong(this.rmSongIdMapping[this.currentRmSong].file);
    },

    switchAutoCall(bAuto: boolean) {
      this.autoCallOn = bAuto;
    },
  }, // end of actions:
  persist: {
    key: "rm-spot-player",
    paths: ["currentRmScheme", "rmCallVolume", "rmCallTimingOffs", "autoCallOn", "autosetLimit"],
  },
});
